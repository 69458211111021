import React from "react";
import SubscriberBgImg from "../../../images/SubscriberBg.png";
import SubscriberImg from "../../../images/pic2.jpeg";

const AssignmentSoldIntro = () => {
  return (
    <div className="container">
      <div className="assignmentSale_intro">
        <div className="row align-items-center gy-4">
          <div className="col-lg-6 col-12 h-100">
            <h3>
              What is an <br />
              Assignment Sale?
            </h3>
            <p>
              An assignment sale isn’t a direct real estate sale; instead, it’s
              the transfer of a contract to purchase a property before its
              completion. Here’s how it works:
            </p>
            <ul className="d-flex flex-column">
              <li>
                <strong>Assignor: </strong>The original buyer agrees to purchase
                a property <br /> (e.g., a pre-construction condo).
              </li>
              <li>
                <strong>Assignee:</strong> The buyer transfers this purchase
                agreement to another party before the property is ready for
                occupancy.
              </li>
              <li>
                <strong>Benefits:</strong> The assignee gains ownership upon
                project completion without the hassle of starting from scratch.{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-12 h-100">
            <div className="position-relative w-100 h-100">
              <img src={SubscriberBgImg} alt="SubscriberBgImg" loading="lazy" className="SubscriberBgImg w-100 h-100" />

              <div
                className="position-absolute"
                style={{ top: "7%", left: "7%" }}
              >
                <h4>
                  1.5M<span>+</span>
                </h4>
                  <p className="project_completed">projects successfully completed</p>
              </div>
              <div
                className="avatar position-absolute  d-flex gap-2 align-items-center"
                style={{ bottom: "7%", left: "7%" }}
              >
                <img src={SubscriberImg} alt="SubscriberImg" loading="lazy" />
                <img src={SubscriberImg} alt="SubscriberImg" loading="lazy" />
                <img src={SubscriberImg} alt="SubscriberImg" loading="lazy" />
                <img src={SubscriberImg} alt="SubscriberImg" loading="lazy" />
                <h5>+</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentSoldIntro;
