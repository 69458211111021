import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import "./SearchField.css";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { SiGooglephotos } from "react-icons/si";
import { FaTools } from "react-icons/fa";
import Loader from "../MapandListing/Loader";
import axios from "axios";
import baseURL from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";

export default function SearchField(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    onSearch,
    setSearchValue,
    setNewInputVal,
    newInputval,
    setSearchResTitle,
    setSearchResDes,
    setToggleIcon,
    toggleIcon,
    handleSearchLocation,
    handleFilterForm,
    isMobileView,
    filterLoading,
    isSearchPage,
  } = props;
  const [apiData, setApiData] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [activeSuggestion, setActiveSuggestion] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const wrapperRef = useRef(null);

  // const [state, setState] = React.useState({
  //   right: false,
  // });

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  //   navigate("/");
  // };

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // FetchData for search bar from API Function
  // eslint-disable-next-line
  const debouncedApiCall = useCallback(
    debounce((query) => {
      axios
        .get(`${baseURL}/api/data/GetSearchbarResponse/${query}`)
        .then((response) => {
          setApiData(response?.data.data);
          setShowLoader(false);
        })
        .catch((error) => {
          console.error("error");
        });
    }, 500),
    []
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenBox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.trimStart();
    setNewInputVal(inputValue);
    setToggleIcon(true);
    setOpenBox(true);

    if (inputValue.length > 0) {
      setOpenBox(true);
    } else {
      setOpenBox(false);
      // setSearchValue("");
      // setSearchResTitle("");
      // setSearchResDes("");
    }

    // Only call the API if the input is not empty
    if (inputValue.length > 0) {
      debouncedApiCall(inputValue);
    } else {
      setShowLoader(true);
    }
  };

  const handleSuggetionResult = (props, anchor) => {
    if (props?.type === "listingId" || props?.type === "Address") {
      navigate(`/assignment-sale/${props?.slug}`);
    } else if (!isSearchPage) {
      const params = new URLSearchParams(location.search);

      const queryString = Array.from(params.entries())
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const newUrl = `${props?.responseTitle}?${queryString}`;
      navigate(newUrl);
    } else {
      navigate(
        `/${props?.responseTitle}${
          props.type !== "Location" ? `?searchtype=${props?.type}` : ""
        }`
      );
      onSearch(props?.responseTitle);
      setSearchResTitle(props?.type);
      setNewInputVal(props?.responseTitle);
      setSearchResDes(props?.responseTitle);
    }
    setOpenBox(false);
    setToggleIcon(false);
  };

  const searchKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      // Handle arrow down key
      e.preventDefault();
      if (
        activeSuggestion === null ||
        activeSuggestion === apiData.length - 1
      ) {
        setActiveSuggestion(0);
      } else {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      // Handle arrow up key
      e.preventDefault();
      if (activeSuggestion === null || activeSuggestion === 0) {
        setActiveSuggestion(apiData.length - 1);
      } else {
        setActiveSuggestion(activeSuggestion - 1);
      }
    } else if (e.key === "Enter") {
      if (
        apiData[activeSuggestion]?.type === "listingId" ||
        apiData[activeSuggestion]?.type === "Address"
      ) {
        navigate(`/assignment-sale/${apiData[activeSuggestion]?.slug}`);
      } else if (!isSearchPage) {
        const params = new URLSearchParams(location.search);

        const queryString = Array.from(params.entries())
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");

        const newUrl = `${apiData[activeSuggestion]?.responseTitle}?${queryString}`;
        navigate(newUrl);
      } else {
        navigate(
          `/${apiData[activeSuggestion]?.responseTitle}${
            apiData[activeSuggestion]?.type !== "Location"
              ? `?searchtype=${apiData[activeSuggestion]?.type}`
              : ""
          }`
        );
        onSearch(apiData[activeSuggestion]?.responseTitle);
        setNewInputVal(apiData[activeSuggestion]?.responseTitle);
        setSearchResTitle(apiData[activeSuggestion]?.type);
        setSearchResDes(apiData[activeSuggestion]?.responseTitle);
      }
      setToggleIcon(false);
      setOpenBox(false);
    }
  };

  // useEffect(() => {
  //   if (getFilterData) {
  //     handleFilterForm();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getFilterData]);

  const handleSearch = () => {
    if (newInputval && isMobileView && !filterLoading) {
      handleFilterForm();
    }
  };

  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="position-relative mob__Borders">
            <div className="d-flex align-items-center mob__Padding">
              <div>
                {toggleIcon ? (
                  <FmdGoodOutlinedIcon
                    className="mv-ic"
                    sx={{
                      color: "orange",
                      fontSize: 30,
                      cursor: "pointer",
                    }}
                    onClick={handleSearchLocation}
                  />
                ) : (
                  <FmdGoodIcon
                    className="mv-ic"
                    sx={{
                      color: "orange",
                      fontSize: 30,
                    }}
                  />
                )}
              </div>

              <div className="w-100 font mv-ph">
                <input
                  type="text"
                  placeholder="Search by region, city, street or ID"
                  value={newInputval}
                  onChange={handleInputChange}
                  onKeyDown={searchKeyDown}
                  className="SearchInput"
                />
              </div>
              <div
                className={`${
                  isMobileView ? "searchBox_searchingIcon" : "d-none"
                }`}
                onClick={handleSearch}
              >
                <SearchIcon
                  sx={{
                    color: "grey",
                    fontSize: 25,
                  }}
                />
              </div>
            </div>
            {openBox ? (
              <div
                ref={wrapperRef}
                className="d-flex align-items-center justify-content-center"
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "white",
                    boxShadow: 4,
                    borderRadius: 2,
                    margin: 0,
                    paddingLeft: 0,
                  }}
                  className="SearchBox"
                >
                  {showLoader ? (
                    <div className="d-flex justify-content-center p-4">
                      <Loader size={40} />
                    </div>
                  ) : (
                    <div className="p-2">
                      <div className="recentSearch font">
                        <p className="m-0 p-1">
                          {apiData?.locations?.length > 0 ||
                          apiData?.listings?.length > 0 ||
                          apiData?.developers?.length > 0
                            ? `${"Recent Search"}`
                            : `${"No Search matches"}`}
                        </p>
                      </div>

                      {apiData?.locations?.slice(0, 3)?.length > 0 && (
                        <>
                          <h5>Locations</h5>
                          {apiData?.locations
                            ?.slice(0, 3)
                            ?.map((suggestion, index) => (
                              <div
                                className={`d-flex px-2 py-2 searchSuggestion ${
                                  activeSuggestion === index && "active__search"
                                }`}
                                key={index}
                                onClick={() => {
                                  handleSuggetionResult(suggestion, anchor);
                                }}
                              >
                                <div
                                  style={{ width: "90%" }}
                                  className="d-flex"
                                >
                                  <div>
                                    <FmdGoodOutlinedIcon />
                                  </div>
                                  <div className="px-1">
                                    <p className="m-0 text-dark search__heading font">
                                      {suggestion.responseTitle}
                                    </p>
                                    <div className="">
                                      <p className="m-0 search__description font">
                                        {suggestion.responseDesc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}

                      {apiData?.listings?.slice(0, 3)?.length > 0 && (
                        <>
                          <hr className="my-1" />
                          <h5>Listings</h5>
                          {apiData?.listings
                            ?.slice(0, 3)
                            ?.map((suggestion, index) => (
                              <div
                                className={`d-flex px-2 py-2 searchSuggestion ${
                                  activeSuggestion === index && "active__search"
                                }`}
                                key={index}
                                onClick={() => {
                                  handleSuggetionResult(suggestion, anchor);
                                }}
                              >
                                <div
                                  style={{ width: "90%" }}
                                  className="d-flex"
                                >
                                  <div>
                                    <SiGooglephotos
                                      color="black"
                                      fontSize="20px"
                                    />
                                  </div>
                                  <div className="px-1">
                                    <p className="m-0 text-dark search__heading font">
                                      {suggestion.responseTitle}
                                    </p>
                                    <div className="">
                                      <p className="m-0 search__description font">
                                        {suggestion.responseDesc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {(suggestion.type === "listingId" ||
                                  suggestion.type === "listing" ||
                                  suggestion.type === "Address") &&
                                  (suggestion?.id === undefined ||
                                  suggestion?.id === 0 ? null : (
                                    <div
                                      className="d-flex"
                                      style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                      }}
                                    >
                                      <p
                                        className="m-0"
                                        style={{
                                          border: "1px solid black",
                                          padding: "0px 5px",
                                          borderRadius: "4px",
                                          width: "45px",
                                        }}
                                      >
                                        {suggestion?.id}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            ))}
                        </>
                      )}

                      {apiData?.developers?.slice(0, 3)?.length > 0 && (
                        <>
                          <hr className="my-1" />
                          <h5>Developers</h5>
                          {apiData?.developers
                            ?.slice(0, 3)
                            ?.map((suggestion, index) => (
                              <div
                                className={`d-flex px-2 py-2 searchSuggestion ${
                                  activeSuggestion === index && "active__search"
                                }`}
                                key={index}
                                onClick={() => {
                                  handleSuggetionResult(suggestion, anchor);
                                }}
                              >
                                <div
                                  style={{ width: "90%" }}
                                  className="d-flex"
                                >
                                  <div>
                                    <FaTools />
                                  </div>
                                  <div className="px-1">
                                    <p className="m-0 text-dark search__heading font">
                                      {suggestion.responseTitle}
                                    </p>
                                    <div className="">
                                      <p className="m-0 search__description font">
                                        {suggestion.responseDesc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            ) : null}
          </div>
        </React.Fragment>
      ))}
    </>
  );
}
